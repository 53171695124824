/** @jsx jsx */
import React from "react";
import { jsx, Container, Heading, Text, Box, Image, Link } from 'theme-ui';
import SectionHeader from 'components/section-header';
import ButtonGroup from 'components/button-group';
import Carousel from 'react-multi-carousel';
import axios from 'axios'
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

Modal.setAppElement(`#___gatsby`);

const customStyles = {
  overlay: {
    zIndex: 1001
  },
  content : {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: '100vh',
    width: '100%',
    position: 'absolute',
    overflow: 'none',
    px: '55px'
  },
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1619 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  laptop: {
    breakpoint: { max: 1619, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default class NewsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newsData: [],
      modalState: false,
      modalTitle: null,
      modalContent: null,
      modalDate: null,
    };

  }
  
  openModal() {
    this.setState({modalState: true});
  }

  closeModal() {
    this.setState({modalState: false});
  }
  
  componentDidMount() {
    axios.get(`https://cbt.dispenda.online/api/posts?token=685cdfd1dcaf9bfc75832b09305dc524`)
      .then(data => data.data)
      .then(res => {
        this.setState({
          newsData: res.data
        });
    });
  }

  render() {
    const { modalState } = this.state;

    return (
      <section id="news" sx={{ variant: 'section.testimonial' }}>
        <Container>
          <SectionHeader slogan="News & Update" title="Up to Date With Our Activities" />
        </Container>
          {
            this.state.newsData.length === 0 && 
            <Text style={{ textAlign: 'center' }}>
              Loading News...
            </Text>
          }
          {this.state.newsData.length > 0 && 
          <Box sx={styles.carouselWrapper}>
            <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="carousel-container"
            customButtonGroup={<ButtonGroup />}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={true}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside
            renderDotsOutside={false}
            responsive={responsive}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
          >
            {this.state.newsData.map((item) => (
              <Box sx={styles.reviewCard} key={`news--key${item.id}`} onClick={() => {
                this.setState({
                  modalState: true,
                  modalTitle:item.title,
                  modalDate: item.created_at,
                  modalContent: item.content,
                });
              }}>
                <Image style={styles.img} src={item.thumbnail} alt="News Image" />
                <Heading as="h3" sx={styles.title}>
                    {item.title}
                </Heading>
              </Box>
            ))}
            </Carousel>
          </Box>
        }          

        <Modal
          isOpen={modalState}
          style={customStyles}
          animation={true}
        >
          <Link sx={{ background: 'transparent', color: 'primary', cursor: 'pointer', position: 'fixed', right: '20px', top: '20px' }}onClick={() => {
            this.closeModal();
            this.setState({
              modalContent: null,
              modalDate: null,
              modalTitle: null,
            })
          }}>
            <FaTimes/>
          </Link>

            <SectionHeader slogan={this.state.modalDate} title={this.state.modalTitle} sx={{ variant: 'a' }} />
            <div dangerouslySetInnerHTML={{ __html: this.state.modalContent }} sx={{ height: '75%', pb: 5, 'overflow-y': 'auto' }}></div>
            <br/>
        </Modal>
      </section>
    );
  }
}

const styles = {
  carouselWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
    mt: '-35px',
    px: '15px',
    '.carousel-container': {
      width: '100%',
      maxWidth: [
        '100%',
        null,
        null,
        '750px',
        '1000px',
        '1180px',
        '1690px',
        null,
        'calc(50% + 865px)',
      ],
      mr: ['auto', null, null, null, null, null, null, null, '-220px',],
      ml: 'auto',
      '.react-multi-carousel-item': {
        transition: 'all 0.25s',
      },
      '.react-multi-carousel-item--active:nth-of-type(4n)': {
        opacity: '0.5',
        '@media screen and (max-width: 1620px)': {
          opacity: 1,
        },
      },
    },
  },
  reviewCard: {
    boxShadow: '0px 0px 1px rgba(38, 78, 118, 0.35)',
    transition: 'all 0.3s',
    textAlign: 'center',
    margin: 'auto',
    img: {
      width: '100%',
      height: '150px',

      objectFit: 'cover',
    },
    bg: 'white',
    m: [
      '28px 5px 30px 5px',
      '28px 20px 30px 20px',
      '28px 15px 30px 15px',
      '28px 15px 30px 15px',
      '30px 20px 40px',
    ],
    '&:hover': {
      boxShadow: '0px 6px 47px rgba(38, 78, 118, 0.1)',
    },
    '.rating': {
      mb: [1, null, null, 2],
      ul: {
        pl: 0,
        listStyle: 'none',
        mb: 0,
        display: 'flex',
      },
      svg: {
        marginRight: '2px',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
      '.star': {
        color: 'yellow',
        mr: '1px',
      },
      '.star-o': {
        color: '#ddd',
        mr: '1px',
      },
    },
    '.card-footer': {
      display: 'flex',
      alignItems: 'center',
      marginTop: [5, null, null, '33px'],
     
    },
  },
  title: {
    fontSize: [1, 3],
    fontWeight: 700,
    mt: [3, null, null, '10px'],
    mb: [3, null, null, '20px'],
    color: 'primary',
    lineHeight: 1.6,
    textAlign: 'center',
    pb: 5,
    px: 5,
  },
  description: {
    fontSize: [1, null, null, 2],
    fontWeight: 'normal',
    color: 'text',
    lineHeight: [1.85, null, 2],
  },
  heading: {
    fontSize: [1, null, null, 2],
    fontWeight: 700,
    mb: '3px',
    color: 'text',
    lineHeight: 1.3,
  },
  designation: {
    color: '#25A0FF',
    fontWeight: '500',
    fontSize: 1,
    lineHeight: 1.4,
  },
};
