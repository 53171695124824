/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Box, Heading, Text } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BannerBG from 'assets/bannerBg.png';

export default function Banner() {
  // Graphql image query
  const dataThumb = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner-thumb.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // Popup video status

  return (
    <section sx={styles.banner} id="about">
      <Container sx={styles.banner.container}>
        
        <Box sx={styles.banner.imageBox}>
          <Img fluid={dataThumb.placeholderImage.childImageSharp.fluid} sx={styles.imageStyle}/>
        </Box>

        <Box sx={styles.banner.contentBox}>
          <Heading as="h1" variant="heroPrimary">
            INDONESIA GO DIGITAL
          </Heading>
          <Text as="p" variant="heroSecondary">
          We Are <b>PINISI ELEKTRA</b>, a company with strong experiences in IT and Telecommunication Industry in Indonesia. Supported by teams which most of them had more than 12 years experience in same field. 
          <br/>
          <br/>
          And we commit to bring Innovative Technology for Government to be More Efficient, Reliable and Productive.
          </Text>
        </Box>
      </Container>
    </section>
  );
}

const styles = {
  banner: {
    overflow: ['hidden', 'initial', null, 'hidden'],
    backgroundImage: `url(${BannerBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: 'top left',
    backgroundSize: 'cover',
    borderBottomRightRadius: [100, 150, null, null,250],
    pt: ['150px', null, null, null, null, null, '160px', '200px'],
    pb: ['100px', null, null, '110px', 50],
    backgroundColor: '#EEEEEE',
    container: {
      display: 'flex',
    },
    contentBox: {
      flex: [1, 1, 1, 1, 1, 2, 2],
      flexDirection: 'column',
    },
    imageBox: {
      flex: 1,
      justifyContent: 'center',
      display: ['none', 'none', 'none', 'none', 'none', 'block'],
      ml: [0, null, null, '-280px', '-115px', '50px', '-100px', '-100px'],
      mr: [0, null, null, '-85px', '-115px', '20px', '0px', '0px'],
      overflow: 'hidden',
      textAlign: 'right',
      alignItems: 'center',
      pr: 10,
      px: 5.
    },
  },
};
