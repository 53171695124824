/** @jsx jsx */
import React from "react";
import { jsx, Container, Heading, Text, Box, Image } from 'theme-ui';
import SectionHeader from 'components/section-header';
import ButtonGroup from 'components/button-group';
import Carousel from 'react-multi-carousel';
import axios from 'axios'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1619 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  laptop: {
    breakpoint: { max: 1619, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default class ClientCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientData: [],
    };
  }

  async componentDidMount() {
    await axios.get(`https://cbt.dispenda.online/api/clients?token=685cdfd1dcaf9bfc75832b09305dc524`)
      .then(data => data.data)
      .then(res => {
        this.setState({
          clientData: res.data
        });
    });

  }
  render() {
    return (
      <section sx={{ variant: 'section.testimonial' }}>
        <Container>
          <SectionHeader slogan="Latest Client" title="They Deserve More" />
        </Container>
          {
            this.state.clientData.length === 0 && 
            <Text style={{ textAlign: 'center' }}>
              Loading Client...
            </Text>
          }
          {this.state.clientData.length > 0 && 
          <Box sx={styles.carouselWrapper}>
            <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="carousel-container"
            customButtonGroup={<ButtonGroup />}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={true}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside
            renderDotsOutside={false}
            responsive={responsive}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
          >
            {this.state.clientData.map((item) => (
              <Box sx={styles.reviewCard} key={`testimonial--key${item.id}`}>
                <Image style={styles.img} src={item.image} alt="Client Image" />
                <Heading as="h3" sx={styles.title}>
                  {item.name}
                </Heading>
              </Box>
            ))}
            </Carousel>
          </Box>
        }          
      </section>
    );
  }
}

const styles = {
  carouselWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
    mt: '-35px',
    px: '15px',
    '.carousel-container': {
      width: '100%',
      maxWidth: [
        '100%',
        null,
        null,
        '750px',
        '1000px',
        '1180px',
        '16900px',
        null,
        'calc(50% + 865px)',
      ],
      mr: ['auto', null, null, null, null, null, null, null, '-220px'],
      ml: 'auto',
      '.react-multi-carousel-item': {
        transition: 'all 0.25s',
      },
      '.react-multi-carousel-item--active:nth-of-type(4n)': {
        opacity: '0.5',
        '@media screen and (max-width: 1620px)': {
          opacity: 1,
        },
      },
    },
  },
  reviewCard: {
    boxShadow: '0px 0px 1px rgba(38, 78, 118, 0.35)',
    transition: 'all 0.3s',
    borderRadius: '6px',
    textAlign: 'center',
    margin: 'auto',
    img: {
      width: '150px',
      height: '150px',
      objectFit: 'cover',
    },
    p: [
      '30px 20px 35px',
      '30px 25px 35px',
      '30px 20px 35px',
      '35px 30px 40px 40px',
      '30px 30px 35px',
      '35px 30px 40px 40px',
    ],
    bg: 'white',
    m: [
      '28px 5px 30px 5px',
      '28px 20px 30px 20px',
      '28px 15px 30px 15px',
      '28px 15px 30px 15px',
      '30px 20px 40px',
    ],
    '&:hover': {
      boxShadow: '0px 6px 47px rgba(38, 78, 118, 0.1)',
    },
    '.rating': {
      mb: [1, null, null, 2],
      ul: {
        pl: 0,
        listStyle: 'none',
        mb: 0,
        display: 'flex',
      },
      svg: {
        marginRight: '2px',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    '.card-footer': {
      display: 'flex',
      alignItems: 'center',
      marginTop: [5, null, null, '33px'],
    },
  },
  title: {
    fontSize: [1, 3],
    fontWeight: 700,
    mt: [3, null, null, '10px'],
    mb: [3, null, null, '20px'],
    color: 'primary',
    lineHeight: 1.6,
    textAlign: 'center'
  },
  description: {
    fontSize: [1, null, null, 2],
    fontWeight: 'normal',
    color: 'text',
    lineHeight: [1.85, null, 2],
  },
  heading: {
    fontSize: [1, null, null, 2],
    fontWeight: 700,
    mb: '3px',
    color: 'text',
    lineHeight: 1.3,
  },
  designation: {
    color: '#25A0FF',
    fontWeight: '500',
    fontSize: 1,
    lineHeight: 1.4,
  },
};
