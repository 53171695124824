import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import KeyFeature from 'sections/key-feature';
import CoreFeature from 'sections/core-feature';
import ClientSection from 'sections/client-section';
import WorkFlow from 'sections/workflow';
import ClientCard from 'sections/client';
import NewsCard from 'sections/news';

// Load other package css file
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO title="PT. Pinisi Elektra" />
        <Banner />
        <KeyFeature />
        <CoreFeature />
        <WorkFlow />
        <ClientSection />
        <ClientCard />
        <NewsCard />
      </Layout>
    </StickyProvider>
  );
}
